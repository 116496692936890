<template>
  <main-layout>
    <template slot="title">
      {{ $t('support.headline') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card>
          <template slot="title">{{ $t('support.faq-headline') }}</template>
          <p>{{ $t('support.faq-line1') }}</p>
          <p class="my-5">
            <oh-button type="primary" @click="openLink($t('help-link'))">{{ $t('support.faq-button') }}</oh-button>
          </p>
          <p>{{ $t('support.faq-line2') }}</p>
          <p class="mt-2">{{ $t('support.faq-line3') }}</p>
        </card>
      </div>
      <div class="m-auto max-w-7xl">
        <card>
          <template slot="title">{{ $t('support.form-headline') }}</template>

          <div v-if="message"
               class="p-6 mb-5 font-bold" :class="messageType === 'error' ? 'bg-red-100' : 'bg-green-100'"
          >
            {{ message }}
          </div>

          <form @submit="createTicket">
            <div class="w-full mb-4">
                <oh-input :label="$t('support.reply-to')" :readonly="true" :value="user.email" id="reply-to"></oh-input>
              <span class="text-xs italic">{{ $t('support.reply-to-help') }}</span>
            </div>
            <div class="w-full mb-4">
              <oh-select :label="$t('support.product')" v-model="form.customer_product" id="product">
                <option value="N/A">{{ $t('support.product-na') }}</option>
                <option :value="product.id" v-for="product in products" :key="product.id">
                  {{ product.settings.name }} ({{ product.product.name }})
                </option>
              </oh-select>
            </div>
            <div class="w-full mb-4">
              <oh-select :label="$t('support.priority')" v-model="form.priority" id="priority">
                <option :value="null">{{ $t('support.priority-na') }}</option>
                <option value="low">{{ $t('support.priority-low') }}</option>
                <option value="normal">{{ $t('support.priority-medium') }}</option>
                <option value="high" v-if="products.length > 0">{{ $t('support.priority-high') }}</option>
                <option value="urgent" v-if="products.length > 0">{{ $t('support.priority-critical') }}</option>
              </oh-select>
              <span class="text-xs italic">{{ $t('support.priority-help') }}</span>
              <div v-if="form.priority === 'urgent'" class="text-red-500">
                <i class="fas fa-exclamation-triangle text-lg mt-4"></i>
                {{ $t('support.priority-urgent-warn')}}
              </div>
            </div>
            <div class="w-full mb-4">
              <oh-input :label="$t('support.subject')" :placeholder="$t('support.subject-placeholder')"
                        v-model="form.subject" id="subject"></oh-input>
            </div>
            <div class="w-full mb-4">
              <oh-textarea :label="$t('support.message')" v-model="form.message" id="message"
                           :placeholder="$t('support.message-placeholder')" additional-class="h-48"></oh-textarea>
            </div>
            <div class="mt-8">
              <oh-button type="primary" @click="createTicket">{{
                  loading ? $t('support.loading') : $t('support.submit')
                }}
              </oh-button>
            </div>

            <div class="text-sm text-gray-600 mt-5">{{ $t('support.support-times') }}</div>
          </form>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {api} from "@/api";
import OhInput from "../components/OhInput";
import OhButton from "../components/OhButton";
import OhTextarea from "../components/OhTextarea";
import OhSelect from "../components/OhSelect";
import {globalData} from "../globalData";

export default {
  data() {
    return {
      message: null,
      messageType: 'success',
      loading: false,
      form: {
        subject: null,
        customer_product: 'N/A',
        priority: null,
        message: null
      },
      tickets: [],
    };
  },
  mounted() {
    globalData.activeRoute = 'support';
    // this.loadTickets();
  },
  methods: {
    async loadTickets() {
      this.tickets = (await api.get('/tickets')).data;
    },
    openLink(url) {
      window.open(url, '_blank').focus();
    },
    async createTicket() {
      this.loading = true;
      try {
        await api.post('/tickets', this.form);
        this.messageType = 'success';
        this.message = this.$t('support.submit-success');
        this.form.subject = null;
        this.form.priority = null;
        this.form.customer_product = 'N/A';
        this.form.message = null;
      } catch (error) {
        this.messageType = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or send us an email at support@oakhost.net.';
        }
      }
      this.loading = false;
    }
  },
  computed: {
    user() {
        return globalData.user
    },
    products() {
      return globalData.products;
    }
  },
  components: {
    OhSelect,
    OhTextarea,
    OhButton,
    OhInput,
    Card,
    MainLayout
  },
}
</script>
